<template>
  <b-row>
    <b-col
      cols="12"
      md="3"
    >
      <b-card>
        <start-date />
        <dead-line />
        <task-types />
        <priorities />
      </b-card>
      <task-files />
    </b-col>
    <b-col>
      <b-card>
        <projects />
        <task-title />
        <task-content />
        <task-users />
      </b-card>
      <save-button
        :save-action="submitForm"
        :is-disabled="submitStatus"
      />
    </b-col>
  </b-row>
</template>
<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import TaskTitle from '@/views/Admin/Tasks/elements/TaskTitle.vue'
import TaskContent from '@/views/Admin/Tasks/elements/TaskContent.vue'
import StartDate from '@/views/Admin/Tasks/elements/StartDate.vue'
import DeadLine from '@/views/Admin/Tasks/elements/DeadLine.vue'
import Priorities from '@/views/Admin/Tasks/elements/Priorities.vue'
import TaskTypes from '@/views/Admin/Tasks/elements/TaskTypes.vue'
import Projects from '@/views/Admin/Tasks/elements/Projects.vue'
import TaskUsers from '@/views/Admin/Tasks/elements/TaskUsers.vue'
import TaskFiles from '@/views/Admin/Tasks/elements/TaskFiles.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'

export default {
  name: 'ItemForm',
  components: {
    BCard,
    BRow,
    BCol,
    TaskTitle,
    TaskContent,
    StartDate,
    DeadLine,
    Priorities,
    TaskTypes,
    Projects,
    TaskUsers,
    TaskFiles,
    SaveButton,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
    submitStatus: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    dataItem() {
      return this.$store.getters['tasks/dataItem']
    },
  },
  created() {
    this.getPriorities()
    this.getTaskTypes()
    this.getTaskUsers()
  },
  methods: {
    getPriorities() {
      this.$store.dispatch('priorities/getDataList')
    },
    getTaskTypes() {
      this.$store.dispatch('taskTypes/getDataList')
    },
    getTaskUsers() {
      this.$store.dispatch('users/getDataList', {
        select: [
          'users.id AS id',
          'users.name AS name',
        ],
        where: {
          'users.status': 1,
        },
      })
    },
  },
}
</script>
