<template>
  <b-card>
    <template v-if="dataItem.files.length">
      <b-list-group>
        <b-list-group-item
          v-for="(item,key) in dataItem.files"
          :key="key"
        >
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <b-link
                :href="baseURL + '/media/task_files/' + item.filename"
                target="_blank"
              >
                {{ item.title }}
              </b-link>
            </div>
            <div>
              <b-button
                class="btn-icon"
                variant="flat-danger"
                pill
                @click="deleteData(item.id,key)"
              >
                <FeatherIcon icon="TrashIcon" />
              </b-button>
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
      <hr>
    </template>
    <template v-if="uploading">
      <pre-loading />
    </template>
    <template v-else>
      <b-form-group>
        <b-form-input
          v-model="taskFile.title"
          placeholder="Dosya Adı"
        />
      </b-form-group>
      <b-form-group>
        <b-form-file
          id="file-upload"
          v-model="taskFile.upload_file"
          placeholder="Bir dosya seçin..."
          drop-placeholder="Dosyayı buraya bırakın..."
          browse-text="Dosya Seçin"
        />
      </b-form-group>
      <b-button
        variant="success"
        block
        :disabled="!taskFile.upload_file"
        @click="uploadFile"
      >
        <FeatherIcon icon="UploadIcon" />
        Dosya Yükle
      </b-button>
    </template>
  </b-card>
</template>
<script>
import {
  BCard, BButton, BFormGroup, BFormInput, BFormFile, BListGroup, BListGroupItem, BLink,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PreLoading from '@/layouts/components/common/PreLoading.vue'

export default {
  name: 'Files',
  components: {
    BCard,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BListGroup,
    BListGroupItem,
    BLink,
    PreLoading,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['tasks/dataItem']
    },
    taskFile() {
      return this.$store.getters['taskFiles/dataItem']
    },
    uploading() {
      return this.$store.getters['taskFiles/uploading']
    },
  },
  methods: {
    uploadFile() {
      this.taskFile.id_tasks = this.dataItem.id
      this.$store.dispatch('taskFiles/uploadData', this.taskFile)
        .then(res => {
          if (res.status) {
            this.showToast({
              variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: res.message,
            })
            this.dataItem.files.push(res.data)
          } else {
            this.showToast({
              variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: res.message,
            })
          }
        })
    },
    deleteData(id, index) {
      this.$store.dispatch('taskFiles/removeData', id)
        .then(response => {
          if (response.status) {
            this.dataItem.files.splice(index, 1)
            this.showToast({
              variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: response.message,
            })
          } else {
            this.showToast({
              variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: response.message,
            })
          }
        })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
